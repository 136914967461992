<template>
  <div class="key-words">
    <div class="key-words-head">
      <el-form :inline="true" ref="form">
        <el-form-item>
          <el-input
            clearable
            v-model.trim="searchForm.groupName"
            style="width: 157px"
            placeholder="请输入方案名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model.trim="searchForm.searchKey"
            clearable
            style="width: 452px"
            placeholder="请输入关键词，多个关键词请用逗号','分割 "
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-top" type="primary" @click="addPlan">
            立即提交
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-popover placement="bottom" trigger="hover">
            <div>
              <p>
                【1】可以将常用关键组添加到这里，我们将会对您所添加关键词进行全网采集
              </p>
              <p>【2】稍后您可以在“舆情数据”模块中，使用这个关键词组进行查询</p>
            </div>
            <span slot="reference" class="head-info">
              <img src="@/assets/imgs/help.png" alt="" />
              <span>使用说明</span>
            </span>
          </el-popover>
        </el-form-item>
      </el-form>
    </div>
    <div class="key-words-total">关键词方案共{{ total }}条</div>
    <div
      class="key-words-content"
      v-loading="loading"
      v-infinite-scroll="getList"
      :infinite-scroll-disabled="scrollDisabled"
      :infinite-scroll-distance="10"
    >
      <div class="key-words-content-child">
        <div class="content-item" v-for="(item, i) in listData" :key="i">
          <div class="content-item-top">
            <div class="item-top-left">
              <img src="@/assets/imgs/i5.png" alt="" />
              <span>方案名：{{ item.groupName }}</span>
            </div>
            <div class="item-top-right">
              <el-popover
                placement="bottom"
                trigger="hover"
                popper-class="keywords-popover"
              >
                <div style="text-align: left; margin: 0">
                  <div>
                    <el-button
                      class="el-icon-video-play"
                      size="mini"
                      type="text"
                      @click="viewVideo(item)"
                    >
                      查看视频
                    </el-button>
                    <el-button
                      class="el-icon-edit"
                      size="mini"
                      type="text"
                      @click="update(item, i)"
                      style="margin-left:0"
                    >
                      编辑
                    </el-button>
                  </div>
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      class="el-icon-delete"
                      style="color: red"
                      @click="delData(item)"
                    >
                      删除
                    </el-button>
                  </div>
                </div>
                <span
                  slot="reference"
                  style="margin-top: 20px !important"
                  class="left-list-info"
                >
                  <img src="@/assets/imgs/i7.png" alt="" />
                </span>
              </el-popover>
            </div>
          </div>

          <div class="content-item-key">
            <div class="content-item-key-top">
              <img src="@/assets/imgs/i6.png" alt="" />
              <span>关键词:</span>
            </div>
            <div class="content-item-key-text">{{ item.searchKey }}</div>
          </div>
          <div class="content-item-time">创建时间：{{ item.createTime }}</div>
        </div>
      </div>
      <li v-if="listData.length > 0">{{ nomoreText }}</li>
    </div>
    <!-- <pagination
      :total="total"
      :page-size="limit"
      :currentPage="current"
      :pagerCount="5"
      background
      :sizesArr="[18, 36, 72]"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    /> -->

    <!-- 弹框 -->
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="577px">
      <div>
        <el-form :inline="true" ref="form" label-width="100px">
          <el-form-item label="分组名称">
            <el-input
              style="width: 370px"
              v-model.trim="submitForm.groupName"
              placeholder="组名"
            ></el-input>
          </el-form-item>
          <el-form-item label="关键词">
            <el-input
              style="width: 370px"
              v-model.trim="submitForm.searchKey"
              type="textarea"
              :rows="1"
              placeholder="关键词 "
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureDialog">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <noData v-if="listData.length === 0" />
  </div>
</template>

<script>
import {
  setKeyWords,
  keyWordsPage,
  putKeyWords,
  delKeyWords,
  keyDetail
} from '@/api/keywords.js'
export default {
  data() {
    return {
      loading: false,
      searchForm: {
        groupName: '',
        searchKey: ''
      },
      listData: [],
      // 当前页
      current: 1,
      // 每页记录数
      limit: 4,
      // 总记录数
      total: 0,
      dialogVisible: false,
      submitForm: { groupName: '', searchKey: '' },
      nomoreText: '加载中...',
      scrollDisabled: false,
      dataIndex: null
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    viewVideo(row) {
      this.$router.push({
        // path: '/retrieval/video',
        name: 'retrievalVideo',
        params: {
          keyWords: row.id
        }
      })
    },
    // 编辑
    update(row, i) {
      //
      this.dataIndex = i
      keyDetail(row.id).then(res => {
        this.submitForm = Object.assign({}, res)
        this.dialogVisible = true
      })
    },
    sureDialog() {
      if (
        this.submitForm.groupName.trim() == '' ||
        this.submitForm.searchKey.trim() == ''
      ) {
        this.$message({
          type: 'warning',
          message: '请输入组名 或 关键词！'
        })
        return
      }
      this.submitForm.searchKey = this.submitForm.searchKey.trim()
      if (!this.specialCharacters(this.submitForm.searchKey)) {
        this.$message({
          type: 'warning',
          message: "关键词请使用 ',' 分割！"
        })
        return
      }
      putKeyWords(this.submitForm).then(res => {
        this.$message({
          type: 'success',
          message: '编辑成功！'
        })
        this.dialogVisible = false
        // this.current=1
        this.listData[this.dataIndex].searchKey = this.submitForm.searchKey
        console.log(this.listData[this.dataIndex])
        // this.getList()
      })
    },
    // 删除
    delData(row) {
      let that = this
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delKeyWords(row.id).then(res => {
            that.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.current = 1
            this.listData = []
            that.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    currentChange(val) {
      this.current = val
      this.getList()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getList()
    },
    getList() {
      this.loading = true
      this.scrollDisabled = true
      keyWordsPage(this.current, this.limit).then(data => {
        this.loading = false
        this.total = data.all
        if (data.items.length > 0) {
          this.current++
          this.scrollDisabled = false
          this.listData = this.listData.concat(data.items)
          if (data.items.length < this.limit) {
            this.nomoreText = '没有更多了...'
          }
        } else {
          this.nomoreText = '没有更多了...'
        }
      })
    },
    addPlan() {
      if (
        this.searchForm.groupName.trim() == '' ||
        this.searchForm.searchKey.trim() == ''
      ) {
        this.$message({
          type: 'warning',
          message: '请输入组名 或 关键词！'
        })
        return
      }
      this.searchForm.searchKey = this.searchForm.searchKey.trim()
      if (!this.specialCharacters(this.searchForm.searchKey)) {
        this.$message({
          type: 'warning',
          message: "关键词请使用 ',' 分割！"
        })
        return
      }
      setKeyWords(this.searchForm).then(res => {
        this.$message({
          type: 'success',
          message: '提交成功！'
        })
        this.searchForm.groupName = ''
        this.searchForm.searchKey = ''
        this.current = 1
        this.listData = []
        this.getList()
      })
    },
    specialCharacters(str) {
      if (!str) return true

      const specialKey =
        "[`~!#$^&*()=|{}': ; '\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。、？‘']"
      for (var i = 0; i < str.length; i++)
        if (specialKey.indexOf(str.substr(i, 1)) != -1) return false

      return true
    }
  }
}
</script>

<style lang="scss">
.key-words {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  .key-words-head {
    width: 100%;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 9px 0 rgb(82 63 105 / 18%);
    .el-form-item {
      margin: 10px 0;
      padding: 0 15px;
    }
    .head-info {
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
        vertical-align: middle;
      }
      span {
        font-size: 12px;
        font-weight: 300;
        color: #4b4b4b;
      }
    }
  }
  .key-words-total {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    background: white;
    margin: 5px 0;
    line-height: 30px;
    padding: 0 10px;
    color: #4b4b4b;
    font-size: 12px;
  }
  .key-words-content {
    width: 100%;
    height: calc(100% - 100px);
    margin-top: 5px;
    border-radius: 5px;
    background: white;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;

    .key-words-content-child {
      width: calc(100% + 16px);
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }
    li {
      list-style: none;
      text-align: center;
      color: #555555;
      font-size: 12px;
    }
  }
  //
  .content-item {
    width: calc(100% / 6 - 9px);
    height: 200px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 15px;
    margin-right: 8px;
    margin-bottom: 8px;
    .content-item-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      .item-top-left {
        img {
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
          margin-left: 10px;
        }
      }
      .item-top-right {
        img {
          width: 14px;
          height: 14px;
          vertical-align: middle;
        }
      }
    }
    .content-item-top::after {
      content: '';
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      position: absolute;

      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
    .content-item-key {
      margin-top: 20px;
      .content-item-key-top {
        span {
          vertical-align: middle;
          margin-left: 5px;
        }
        img {
          vertical-align: middle;
        }
      }
      .content-item-key-text {
        margin-top: 10px;
        padding: 0 15px;
        border-bottom: 1px solid #e5e5e5;
        height: 70px;
        font-size: 12px;
      }
    }
    .content-item-time {
      font-size: 12px;
      padding-top: 13px;
      font-weight: 300;
      color: #1a1a1b;
    }
  }
}
.keywords-popover {
  width: 80px !important;
  min-width: 50px !important;
  padding: 5px;
}
</style>
